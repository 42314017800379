import React from "react";

import { graphql } from "gatsby";

import { ContentLayout } from "../../layouts/content-layout";
import { DefaultLayout } from "../../layouts/default-layout";

// CSS
import "./affiliate-disclosure.scss";

const AffiliateDisclosureTemplate = ({ data, pageContext }) => {
    const pageDetails = {
        title: pageContext.title
    };
    const affiliateDisclosure = (data && data.site && data.site.siteMetadata && data.site.siteMetadata.affiliateDisclosure) || [];

    return (
        <DefaultLayout pageDetails={pageDetails}>
            <ContentLayout title={pageContext.title}>
                <div className="p-affiliate-disclosure__container u-margin-top--2">
                    {affiliateDisclosure && affiliateDisclosure.map((paragraph) => {
                        return <><p>{paragraph}</p><br /></>;
                    })}
                </div>
            </ContentLayout>
        </DefaultLayout>
    );
};

export const query = graphql`
    query AffiliateDisclosureQuery {
        site {
            siteMetadata {
                affiliateDisclosure
            }
        }
    }
`;

export default AffiliateDisclosureTemplate;